import React from "react";
import { graphql } from "gatsby";

import get from "lodash-es/get";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ProjectDetail from "../components/projectDetail";

const ProjectTemplate = (props) => {
  const project = get(props, "data.contentfulProject", {});
  const image = get(project, "previewImage.localFile.publicURL");
  const desc = get(project, "excerpt.excerpt");

  return (
    <Layout>
      <SEO
        title={`${project.title} | Projects | Younics`}
        description={desc}
        image={image}
      />
      <ProjectDetail isPage={true} project={project} />
    </Layout>
  );
};

export default ProjectTemplate;

export const pageQuery = graphql`
  fragment ProjectDetail on ContentfulProject {
    ...Project
    webUrl
    appStoreUrl
    googlePlayUrl
    reference {
      ... on ContentfulReference {
        id
        quote {
          quote
        }
        author
        color
        logo {
          title
          description
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        avatar {
          title
          description
          localFile {
            childImageSharp {
              fixed(width: 36, height: 36) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    originalState {
      ... on ContentfulProjectSection {
        title
        markup {
          json
        }
      }
    }
    desiredState {
      ... on ContentfulProjectSection {
        title
        markup {
          json
        }
      }
    }
    howWeGotThere {
      ... on ContentfulProjectSection {
        title
        markup {
          json
        }
      }
    }
    theRealValue {
      ... on ContentfulProjectSection {
        title
        markup {
          json
        }
      }
    }
    genericSection {
      ... on ContentfulProjectSection {
        title
        markup {
          json
        }
        markup2 {
          json
        }
        imageGallery {
          title
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
  query ProjectBySlug($slug: String!, $locale: String) {
    contentfulProject(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      ...ProjectDetail
    }
  }
`;
